import React from 'react'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import pdf from 'src/assets/politica_de_privadade_compremais.pdf'

import '../../styles/pages/privacidade.scss'

const Privacy = () => {
  const { privacyBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Política de Privacidade"
      banners={privacyBanners}
    >
      <div className="privacy-container">
        <p>
          <strong>SUPERPÃO SUPERMERCADO S/A – COMPRE MAIS </strong>, com sede no
          Estado do Paraná/PR, na Cidade de Guarapuava, na Rua Guaíra, 5555 –
          Bloco B – 2º Andar, Boqueirão, CEP: 85020-000, inscrito no CNPJ/MF sob
          o nº 77883320/0001-61, (“Lojista”), empresa que disponibiliza o
          Programa de Descontos, Cliente Compre Mais (“Programa”), aos clientes
          que realizem compras em seus estabelecimentos (“Usuário” ou
          “Usuários”), vem, por intermédio desta Política de Privacidade
          (“Política”), apresentar aos seus Usuários as informações essenciais
          sobre a forma que os seus dados serão utilizados e tratados.
        </p>
        <p>
          <b>
            A coleta, o uso e o compartilhamento dos dados pessoais dos Usuários
            do Lojista ficarão sujeitos a esta Política de Privacidade e suas
            atualizações. Ao acessar o site
            clientecompremais.izio.com.br/portalcliente ou Aplicativo, o Usuário
            deverá ler a presente Política, manifestando sua concordância com
            relação às suas disposições, por meio do botão disponibilizado para
            tanto. Por meio de tal ato, o Usuário demonstra sua manifestação
            livre, expressa, informada e inequívoca, sem quaisquer reservas,
            acerca de todo o conteúdo deste documento, nos termos da Lei Geral
            de Proteção de Dados (Lei nº13.709/2018).
          </b>
        </p>
        <p>
          Para entrar em contato com o Lojista, basta enviar um e-mail para:
          <br />
          <a href="mailto: marketing@superpao.com.br">
            marketing@superpao.com.br
          </a>
        </p>
        <p>
          <a
            href={pdf}
            download="politica_de_privadade_compremais.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Privacy
